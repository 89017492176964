<template>
    <div class="p-5 text-xs">
        <!-- Card Container -->
        <div class="rounded-2xl bg-white p-5 py-13 shadow-md">
            <!-- Card Header -->
            <div class="flex items-center justify-between mb-6">
                <span class="font-semibold text-xl">Edit Tiket</span>
            </div>

            <!-- Form Container -->
            <ValidationObserver v-slot="{ invalid }" ref="form">
                <form @submit.prevent="updateTicket" class="w-full">
                    <!-- Form Grid -->
                    <div class="grid grid-cols-1 md:grid-cols-3 gap-4">
                        <!-- Device -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="device_id" class="block mb-2 text-sm font-medium">Device</label>
                                <div class="relative">
                                    <select v-model="ticketData.device_id" id="device_id"
                                        class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700"
                                        disabled>
                                        <option v-for="device in devices" :key="device.id" :value="device.id">
                                            {{ device.code }} - {{ device.name }}
                                        </option>
                                    </select>
                                </div>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Customer ID -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="customer_id" class="block mb-2 text-sm font-medium">Pelanggan*</label>
                                <div class="relative">
                                    <select v-model="ticketData.customer_id" id="customer_id"
                                        class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700">
                                        <option v-for="customer in customers" :key="customer.id" :value="customer.id">
                                            {{ customer.company_name }}
                                        </option>
                                    </select>
                                </div>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Name -->
                        <div class="mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="name" class="block mb-2 text-sm font-medium">Judul Keluhan*</label>
                                <div class="relative">
                                    <input v-model="ticketData.name" id="name" type="text"
                                        class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700" />
                                </div>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>

                        <!-- Description (Full-width) -->
                        <div class="col-span-1 md:col-span-3 mb-4">
                            <ValidationProvider rules="required" v-slot="{ errors }">
                                <label for="description" class="block mb-2 text-sm font-medium">Deskripsi Keluhan*</label>
                                <div class="relative">
                                    <textarea v-model="ticketData.description" id="description"
                                        class="block border border-gray-300 w-full p-3 rounded text-sm bg-white text-gray-700"></textarea>
                                </div>
                                <span class="text-red-600 text-xs">{{ errors[0] }}</span>
                            </ValidationProvider>
                        </div>
                    </div>

                    <!-- Buttons -->
                    <div class="mt-6 flex justify-end">
                        <button type="button" @click="$router.push('/tiket')"
                            class="py-2 px-8 rounded border border-gray-300 mr-2 hover:bg-gray-100">
                            Cancel
                        </button>
                        <button :disabled="invalid" type="submit"
                            class="py-2 px-8 rounded bg-blue-500 text-white hover:bg-blue-700">
                            Update
                        </button>
                    </div>
                </form>
            </ValidationObserver>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { ValidationProvider, ValidationObserver } from "vee-validate/dist/vee-validate.full";
import globalMixin from '../../mixin/global'
import device from '../../store/device';
export default {
    data() {
        return {
            ticketData: {
                priority_id: '',
                customer_id: '',
                device_id: '',
                name: '',
                description: '',
            },
            customers: [],
            devices: [],
            default_url: process.env.VUE_APP_IMAGES
        }
    },
    mixins: [globalMixin],
    methods: {
        async updateTicket() {
            let formData = new FormData()
            Object.keys(this.ticketData).forEach(key => {
                if (this.ticketData[key] !== null && this.ticketData[key] !== '') {
                    formData.append(key, this.ticketData[key])
                }
            })
            try {
                const response = await axios.put(`v1/tiket/${this.$route.params.id}`, formData, this.config)
                this.$snack.success({
                    text: 'Ticket updated successfully!'
                })
                this.$router.push('/tiket')
            } catch (error) {
                if (error.response && error.response.status === 400) {
                    this.$refs.form.setErrors(error.response.data.message)
                }
                this.$snack.error({
                    text: 'Failed to update ticket'
                })
            }
        },
        fetchTicketData() {
            this.axios.get(`v1/tiket/${this.$route.params.id}`, this.config)
                .then((response) => {
                    this.ticketData = response.data;
                })
                .catch((error) => {
                    console.error('Error fetching ticket data:', error);
                });
        },
        fetchCustomers() {
            this.axios.get('v1/customer', this.config)
                .then((ress) => {
                    this.customers = ress.data.rows
                })
        },
        fetchDevices() {
            this.axios.get('v1/device', this.config)
                .then((ress) => {
                    this.devices = ress.data.rows;
                })
        }
    },
    computed: {
        ...mapGetters({
            token: 'auth/token',
        }),
    },
    created() {
        this.fetchCustomers();
        this.fetchDevices();
        this.fetchTicketData();
    },
    components: {
        ValidationProvider, ValidationObserver
    }
}
</script>